import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Box, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Card, 
  CardContent, 
  Link 
} from '@mui/material';
import { getGames, createTeam } from '../services/api';
import Toaster from '../common/Toaster';
import axios from 'axios';

interface Game {
  id: string;
  name: string;
}

const CreateTeam: React.FC = () => {
  const [teamName, setTeamName] = useState('');
  const [teamTag, setTeamTag] = useState('');
  const [selectedGame, setSelectedGame] = useState('');
  const [games, setGames] = useState<Game[]>([]);
  const [toaster, setToaster] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });
  const [loading, setLoading] = useState(false);
  const [teamLink, setTeamLink] = useState('');

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await getGames();
        setGames(response.data.games);
      } catch (error) {
        console.error('Failed to fetch games:', error);
        setToaster({
          open: true,
          message: 'Failed to fetch games. Please try again later.',
          severity: 'error',
        });
      }
    };

    fetchGames();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setTeamLink('');

    try {
      const response = await createTeam({
        team_name: teamName,
        team_tag: teamTag,
        game: selectedGame,
      });

      setToaster({
        open: true,
        message: response.data.message,
        severity: 'success',
      });

      if (response.data.team_link) {
        setTeamLink(response.data.team_link);
      }

      // Reset form
      setTeamName('');
      setTeamTag('');
      setSelectedGame('');
    } catch (error) {
      console.error('Failed to create team:', error);
      let errorMessage = 'Failed to create team. Please try again later.';
      
      if (axios.isAxiosError(error) && error.response) {
        errorMessage = error.response.data?.error || errorMessage;
      }

      setToaster({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseToaster = () => {
    setToaster({ ...toaster, open: false });
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            Create Team
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              margin="normal"
              required
              inputProps={{ maxLength: 15 }}
              helperText="Max 15 characters"
              sx={{
                mb: 2,
                '& .MuiInputBase-input': { color: 'text.primary' },
                '& .MuiInputLabel-root': { color: 'text.secondary' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'divider' },
                  '&:hover fieldset': { borderColor: 'primary.main' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                },
              }}
            />
            <TextField
              fullWidth
              label="Team Tag"
              value={teamTag}
              onChange={(e) => setTeamTag(e.target.value)}
              margin="normal"
              required
              inputProps={{ maxLength: 8 }}
              helperText="Max 8 characters"
              sx={{
                mb: 2,
                '& .MuiInputBase-input': { color: 'text.primary' },
                '& .MuiInputLabel-root': { color: 'text.secondary' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'divider' },
                  '&:hover fieldset': { borderColor: 'primary.main' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                },
              }}
            />
            <FormControl fullWidth margin="normal" required sx={{ mb: 2 }}>
              <InputLabel id="game-select-label" sx={{ color: 'text.secondary' }}>Game</InputLabel>
              <Select
                labelId="game-select-label"
                value={selectedGame}
                onChange={(e) => setSelectedGame(e.target.value)}
                label="Game"
                sx={{
                  color: 'text.primary',
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: 'divider' },
                  '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.main' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.main' },
                  '& .MuiSvgIcon-root': { color: 'text.primary' },
                }}
              >
                {games.map((game) => (
                  <MenuItem key={game.id} value={game.id}>
                    {game.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{ 
                  minWidth: '200px',
                  backgroundColor: 'primary.main',
                  '&:hover': { backgroundColor: 'primary.dark' },
                }}
              >
                {loading ? 'Creating...' : 'Create Team'}
              </Button>
            </Box>
          </form>
          {teamLink && (
            <Box mt={4}>
              <Typography variant="body1" sx={{ color: 'text.primary' }}>
                Team created successfully! View your team here: 
                <Link href={teamLink} target="_blank" rel="noopener noreferrer" sx={{ color: 'primary.main', ml: 1 }}>
                  {teamLink}
                </Link>
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
};

export default CreateTeam;
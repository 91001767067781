import React, { useState } from 'react';
import { getSections, createSections, updateSections } from '../services/api';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  Divider,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

interface Section {
  name: string;
  tournamentType: string;
  competitions: string[];
}

const Sections: React.FC = () => {
  const [organizerId, setOrganizerId] = useState('');
  const [sections, setSections] = useState<Section[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasExistingSections, setHasExistingSections] = useState(false);

  const handleFetch = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await getSections(organizerId);
      if (!response.data.payload || !response.data.payload.sections) {
        setHasExistingSections(false);
        setSections([{ name: '', tournamentType: 'championship', competitions: [''] }]);
      } else {
        setHasExistingSections(true);
        setSections(response.data.payload.sections);
      }
    } catch (err) {
      setError('Error fetching sections');
      console.error(err);
      setSections([]);
    }
    setLoading(false);
  };

  const handleAddSection = () => {
    setSections([...sections, { name: '', tournamentType: 'championship', competitions: [''] }]);
  };

  const handleRemoveSection = (index: number) => {
    const newSections = sections.filter((_, i) => i !== index);
    setSections(newSections);
  };

  const handleAddCompetition = (sectionIndex: number) => {
    const newSections = [...sections];
    newSections[sectionIndex].competitions.push('');
    setSections(newSections);
  };

  const handleRemoveCompetition = (sectionIndex: number, competitionIndex: number) => {
    const newSections = [...sections];
    newSections[sectionIndex].competitions = newSections[sectionIndex].competitions.filter(
      (_, i) => i !== competitionIndex
    );
    setSections(newSections);
  };

  const handleUpdateSection = (index: number, field: keyof Section, value: string | string[]) => {
    const newSections = [...sections];
    newSections[index] = { ...newSections[index], [field]: value };
    setSections(newSections);
  };

  const handleUpdateCompetition = (sectionIndex: number, competitionIndex: number, value: string) => {
    const newSections = [...sections];
    newSections[sectionIndex].competitions[competitionIndex] = value;
    setSections(newSections);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    try {
      const apiCall = hasExistingSections ? updateSections : createSections;
      await apiCall(organizerId, { sections });
      setError('Sections updated successfully!');
    } catch (err) {
      setError('Error updating sections');
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            Manage Sections
          </Typography>

          <Box sx={{ mb: 3 }}>
            <TextField
              fullWidth
              label="Organizer ID"
              value={organizerId}
              onChange={(e) => setOrganizerId(e.target.value)}
              margin="normal"
              sx={{
                mb: 2,
                '& .MuiInputBase-input': { color: 'text.primary' },
                '& .MuiInputLabel-root': { color: 'text.secondary' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'divider' },
                  '&:hover fieldset': { borderColor: 'primary.main' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                },
              }}
            />
            <Button 
              variant="contained" 
              onClick={handleFetch} 
              disabled={loading || !organizerId}
              sx={{ 
                minWidth: '200px',
                backgroundColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.dark' },
              }}
            >
              {loading ? <CircularProgress size={24} /> : 'Fetch Sections'}
            </Button>
          </Box>

          {error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}

          {(sections && sections.length > 0) && (
            <>
              <List>
                {sections.map((section, sectionIndex) => (
                  <React.Fragment key={sectionIndex}>
                    <ListItem>
                      <Box sx={{ width: '100%' }}>
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                          <TextField
                            fullWidth
                            label="Section Name"
                            value={section.name}
                            onChange={(e) => handleUpdateSection(sectionIndex, 'name', e.target.value)}
                            sx={{
                              '& .MuiInputBase-input': { color: 'text.primary' },
                              '& .MuiInputLabel-root': { color: 'text.secondary' },
                            }}
                          />
                          <IconButton 
                            onClick={() => handleRemoveSection(sectionIndex)}
                            sx={{ color: 'error.main' }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>

                        <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                          Championship IDs:
                        </Typography>
                        {section.competitions.map((competition, competitionIndex) => (
                          <Box key={competitionIndex} sx={{ display: 'flex', gap: 2, mb: 1 }}>
                            <TextField
                              fullWidth
                              size="small"
                              value={competition}
                              onChange={(e) =>
                                handleUpdateCompetition(sectionIndex, competitionIndex, e.target.value)
                              }
                              sx={{
                                '& .MuiInputBase-input': { color: 'text.primary' },
                              }}
                            />
                            <IconButton
                              size="small"
                              onClick={() => handleRemoveCompetition(sectionIndex, competitionIndex)}
                              sx={{ color: 'error.main' }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                        <Button
                          startIcon={<AddIcon />}
                          onClick={() => handleAddCompetition(sectionIndex)}
                          sx={{ mt: 1, color: 'primary.main' }}
                        >
                          Add Championship ID
                        </Button>
                      </Box>
                    </ListItem>
                    <Divider sx={{ my: 2 }} />
                  </React.Fragment>
                ))}
              </List>

              <Box sx={{ mt: 2, display: 'flex', gap: 2, justifyContent: 'center' }}>
                <Button 
                  startIcon={<AddIcon />} 
                  onClick={handleAddSection}
                  sx={{ color: 'primary.main' }}
                >
                  Add Section
                </Button>
                <Button 
                  variant="contained" 
                  onClick={handleSubmit} 
                  disabled={loading}
                  sx={{ 
                    backgroundColor: 'primary.main',
                    '&:hover': { backgroundColor: 'primary.dark' },
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Sections;

import React, { useState } from 'react';
import {
  Typography,
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { inviteTeamMembers } from '../services/api';
import Toaster from '../common/Toaster';

interface InviteResult {
  userInput: string;
  userId: string | null;
  inviteResult: { result: string; message: string };
  acceptResult: { result: string; message: string };
}

const InviteTeamMembers: React.FC = () => {
  const [teamId, setTeamId] = useState('');
  const [members, setMembers] = useState(['']);
  const [loading, setLoading] = useState(false);
  const [toaster, setToaster] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });
  const [inviteResults, setInviteResults] = useState<InviteResult[]>([]);

  const handleAddMember = () => {
    if (members.length < 10) {
      setMembers([...members, '']);
    }
  };

  const handleRemoveMember = (index: number) => {
    const newMembers = members.filter((_, i) => i !== index);
    setMembers(newMembers);
  };

  const handleMemberChange = (index: number, value: string) => {
    const newMembers = [...members];
    newMembers[index] = value;
    setMembers(newMembers);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setInviteResults([]);

    try {
      const response = await inviteTeamMembers({
        teamId,
        members: members.filter(member => member.trim() !== ''),
      });

      setInviteResults(response.data.results);

      const successCount = response.data.results.filter(
        (result: InviteResult) => result.inviteResult.result === 'ok' && result.acceptResult.result === 'ok'
      ).length;

      setToaster({
        open: true,
        message: `Successfully invited ${successCount} out of ${response.data.results.length} members.`,
        severity: 'success',
      });

      // Reset form
      setTeamId('');
      setMembers(['']);
    } catch (error) {
      console.error('Failed to invite team members:', error);
      setToaster({
        open: true,
        message: 'Failed to invite team members. Please try again later.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseToaster = () => {
    setToaster({ ...toaster, open: false });
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            Invite Team Members
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Team ID"
              value={teamId}
              onChange={(e) => setTeamId(e.target.value)}
              margin="normal"
              required
              sx={{
                mb: 3,
                '& .MuiInputBase-input': { color: 'text.primary' },
                '& .MuiInputLabel-root': { color: 'text.secondary' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'divider' },
                  '&:hover fieldset': { borderColor: 'primary.main' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                },
              }}
            />
            {members.map((member, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                  fullWidth
                  label={`Member ${index + 1}`}
                  value={member}
                  onChange={(e) => handleMemberChange(index, e.target.value)}
                  margin="normal"
                  required={index === 0}
                  sx={{
                    '& .MuiInputBase-input': { color: 'text.primary' },
                    '& .MuiInputLabel-root': { color: 'text.secondary' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'divider' },
                      '&:hover fieldset': { borderColor: 'primary.main' },
                      '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                    },
                  }}
                />
                {index > 0 && (
                  <IconButton onClick={() => handleRemoveMember(index)} color="error" sx={{ ml: 1 }}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                type="button"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddMember}
                disabled={members.length >= 10}
                sx={{ 
                  borderColor: 'primary.main',
                  color: 'primary.main',
                  '&:hover': { borderColor: 'primary.dark', color: 'primary.dark' },
                }}
              >
                Add Member
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{ 
                  backgroundColor: 'primary.main',
                  '&:hover': { backgroundColor: 'primary.dark' },
                }}
              >
                {loading ? 'Inviting...' : 'Invite Members'}
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
      {inviteResults.length > 0 && (
        <Card sx={{ mt: 4, width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
          <CardContent>
            <Typography variant="h5" gutterBottom sx={{ color: 'primary.main' }}>
              Invite Results
            </Typography>
            <List>
              {inviteResults.map((result, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={result.userInput}
                    secondary={
                      result.inviteResult.result === 'ok' && result.acceptResult.result === 'ok'
                        ? 'Successfully invited and accepted'
                        : `Error: ${result.inviteResult.message || result.acceptResult.message}`
                    }
                    primaryTypographyProps={{ color: 'text.primary' }}
                    secondaryTypographyProps={{ color: 'text.secondary' }}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
};

export default InviteTeamMembers;
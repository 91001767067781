// src/components/Championships/TeamSignup.tsx

import React, { useState } from 'react';
import {
  Typography,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CircularProgress,
  SelectChangeEvent,
} from '@mui/material';
import { getTeamData, performTeamSignup } from '../services/api';
import Toaster from '../common/Toaster';
import axios from 'axios';

interface Player {
  user_id: string;
  nickname: string;
}

const TeamSignup: React.FC = () => {
  const [teamId, setTeamId] = useState('');
  const [champId, setChampId] = useState('');
  const [teamSize, setTeamSize] = useState(5);
  const [members, setMembers] = useState<Player[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const [fetchingTeam, setFetchingTeam] = useState(false);
  const [toaster, setToaster] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

  const fetchTeamData = async () => {
    if (!teamId) {
      setToaster({ open: true, message: 'Please enter a team ID', severity: 'error' });
      return;
    }

    setFetchingTeam(true);
    try {
      const response = await getTeamData(teamId);
      setMembers(response.data.members);
      updatePlayerSelects(response.data.members);
    } catch (error) {
      setToaster({ open: true, message: 'Failed to fetch team data', severity: 'error' });
    } finally {
      setFetchingTeam(false);
    }
  };

  const updatePlayerSelects = (teamMembers: Player[]) => {
    const newSelectedPlayers: { [key: string]: string } = {};
    for (let i = 1; i <= 5; i++) {
      if (i <= teamSize && teamMembers[i - 1]) {
        newSelectedPlayers[`player${i}`] = teamMembers[i - 1].user_id;
      }
    }
    setSelectedPlayers(newSelectedPlayers);
  };

  const handleTeamSizeChange = (event: SelectChangeEvent<number>) => {
    const newSize = event.target.value as number;
    setTeamSize(newSize);
    updatePlayerSelects(members);
  };

  const handlePlayerChange = (event: SelectChangeEvent<string>) => {
    const name = event.target.name as string;
    const value = event.target.value as string;
    setSelectedPlayers(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const roster = Object.values(selectedPlayers).filter(id => id !== '').slice(0, teamSize);
    const substitutes = [
      selectedPlayers.substitute1,
      selectedPlayers.substitute2,
      selectedPlayers.substitute3
    ].filter(id => id);
    const coach = selectedPlayers.coach ? [selectedPlayers.coach] : [];

    try {
      const response = await performTeamSignup({
        teamId,
        champId,
        teamSize,
        roster,
        substitutes,
        coach
      });
      setToaster({
        open: true,
        message: response.data.message,
        severity: 'success',
      });
    } catch (error) {
      let errorMessage = 'Failed to sign up team';
      if (axios.isAxiosError(error) && error.response) {
        errorMessage = error.response.data?.message || errorMessage;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      setToaster({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseToaster = () => {
    setToaster(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            Team Signup Form
          </Typography>
          <TextField
            fullWidth
            label="Team ID"
            value={teamId}
            onChange={(e) => setTeamId(e.target.value)}
            margin="normal"
            required
            sx={{ mb: 2 }}
          />
          <Button
            onClick={fetchTeamData}
            variant="contained"
            disabled={fetchingTeam}
            sx={{ mb: 2 }}
          >
            {fetchingTeam ? <CircularProgress size={24} /> : 'Fetch Team Data'}
          </Button>

          {members.length > 0 && (
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth margin="normal" sx={{ mb: 2 }}>
                <InputLabel id="team-size-label">Team Size</InputLabel>
                <Select
                  labelId="team-size-label"
                  value={teamSize}
                  onChange={handleTeamSizeChange}
                >
                  {[2, 3, 4, 5].map(size => (
                    <MenuItem key={size} value={size}>{size}v{size}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              {[...Array(teamSize)].map((_, index) => (
                <FormControl key={`player${index + 1}`} fullWidth margin="normal" sx={{ mb: 2 }}>
                  <InputLabel id={`player-${index + 1}-label`}>Player {index + 1}</InputLabel>
                  <Select
                    labelId={`player-${index + 1}-label`}
                    name={`player${index + 1}`}
                    value={selectedPlayers[`player${index + 1}`] || ''}
                    onChange={handlePlayerChange}
                    required
                  >
                    {members.map(player => (
                      <MenuItem key={player.user_id} value={player.user_id}>
                        {player.nickname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}

              {[1, 2, 3].map(index => (
                <FormControl key={`substitute${index}`} fullWidth margin="normal" sx={{ mb: 2 }}>
                  <InputLabel id={`substitute-${index}-label`}>Substitute {index}</InputLabel>
                  <Select
                    labelId={`substitute-${index}-label`}
                    name={`substitute${index}`}
                    value={selectedPlayers[`substitute${index}`] || ''}
                    onChange={handlePlayerChange}
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {members.map(player => (
                      <MenuItem key={player.user_id} value={player.user_id}>
                        {player.nickname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}

              <FormControl fullWidth margin="normal" sx={{ mb: 2 }}>
                <InputLabel id="coach-label">Coach</InputLabel>
                <Select
                  labelId="coach-label"
                  name="coach"
                  value={selectedPlayers.coach || ''}
                  onChange={handlePlayerChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {members.map(player => (
                    <MenuItem key={player.user_id} value={player.user_id}>
                      {player.nickname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="Championship ID"
                value={champId}
                onChange={(e) => setChampId(e.target.value)}
                margin="normal"
                required
                sx={{ mb: 2 }}
              />

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  sx={{ minWidth: '200px' }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Sign Up Team'}
                </Button>
              </Box>
            </form>
          )}
        </CardContent>
      </Card>
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
};

export default TeamSignup;
import React from 'react';
import { Typography, Box } from '@mui/material';

const ManageRegions: React.FC = () => {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Manage Cronjobs
      </Typography>
      <Typography>
        This page is under construction. Cronjob management functionality will be added soon.
      </Typography>
    </Box>
  );
};

export default ManageRegions;
import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Avatar, Container, Grid } from '@mui/material';
import axios from 'axios';

interface UserProfile {
  nickname: string;
  avatar: string;
  country: string;
  faceit_url: string;
  roles: string[];
}

export default function Profile() {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('https://tops.services/api/user', { withCredentials: true });
        setProfile(response.data);
      } catch (err) {
        setError('Failed to load profile');
        console.error('Error fetching profile:', err);
      }
    };

    fetchProfile();
  }, []);

  if (error) {
    return (
      <Container>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!profile) {
    return (
      <Container>
        <Typography align="center">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar
              src={profile.avatar}
              alt={profile.nickname}
              sx={{ width: 200, height: 200 }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box>
              <Typography variant="h4" gutterBottom>
                {profile.nickname}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Country: {profile.country}
              </Typography>
              <Typography variant="body1" gutterBottom>
                FACEIT Profile: <a href={profile.faceit_url} target="_blank" rel="noopener noreferrer">{profile.faceit_url}</a>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Roles: {profile.roles.join(', ')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

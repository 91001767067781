import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import Sidebar from './Sidebar';
import theme from '../../Theme';

export default function Layout() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
        <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: { sm: `calc(100% - ${theme.spacing(30)})` },
            ml: { sm: theme.spacing(30) },
            p: theme.spacing(3),
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
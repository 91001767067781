import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Chip, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  FormGroup, 
  FormControlLabel, 
  Checkbox,
  Card,
  CardContent,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAllUsers, updateUserRoles } from '../services/api';
import Toaster from '../common/Toaster';

interface User {
  _id: string;
  faceit_id: string;
  email: string;
  nickname: string;
  roles: string[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ManageUsers: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [toaster, setToaster] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

  const availableRoles = ['user', 'Admin', 'LiveOPS', 'Owner'];

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await getAllUsers();
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setToaster({
        open: true,
        message: 'Failed to fetch users. Please try again.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (user: User) => {
    setSelectedUser(user);
    setSelectedRoles(user.roles);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
    setSelectedRoles([]);
  };

  const handleRoleChange = (role: string) => {
    setSelectedRoles(prev => 
      prev.includes(role) ? prev.filter(r => r !== role) : [...prev, role]
    );
  };

  const handleUpdateRoles = async () => {
    if (selectedUser) {
      setLoading(true);
      try {
        await updateUserRoles(selectedUser._id, selectedRoles);
        await fetchUsers();
        handleCloseDialog();
        setToaster({
          open: true,
          message: 'User roles updated successfully',
          severity: 'success',
        });
      } catch (error) {
        console.error('Error updating user roles:', error);
        setToaster({
          open: true,
          message: 'Failed to update user roles. Please try again.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCloseToaster = () => {
    setToaster(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            User Management
          </Typography>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper} elevation={3}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Nickname</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Roles</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user._id} hover>
                      <TableCell>{user.nickname}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {user.roles.map((role) => (
                          <StyledChip key={role} label={role} color="primary" variant="outlined" />
                        ))}
                      </TableCell>
                      <TableCell>
                        <Button 
                          variant="contained" 
                          color="primary"
                          onClick={() => handleOpenDialog(user)}
                          sx={{ 
                            textTransform: 'none',
                            backgroundColor: 'primary.main',
                            '&:hover': { backgroundColor: 'primary.dark' },
                          }}
                        >
                          Edit Roles
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>

      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            color: 'text.primary',
          }
        }}
      >
        <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'common.white' }}>
          Edit User Roles
        </DialogTitle>
        <DialogContent sx={{ paddingTop: 3 }}>
          <FormGroup>
            {availableRoles.map((role) => (
              <FormControlLabel
                key={role}
                control={
                  <Checkbox
                    checked={selectedRoles.includes(role)}
                    onChange={() => handleRoleChange(role)}
                    color="primary"
                  />
                }
                label={role}
                sx={{ color: 'text.primary' }}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button 
            onClick={handleCloseDialog} 
            variant="outlined" 
            color="primary"
            sx={{
              borderColor: 'primary.main',
              color: 'primary.main',
              '&:hover': { borderColor: 'primary.dark', color: 'primary.dark' },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleUpdateRoles} 
            variant="contained" 
            color="primary"
            sx={{ 
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.dark' },
            }}
          >
            Update Roles
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
};

export default ManageUsers;
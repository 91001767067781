import React, { useState } from 'react';
import {
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
} from '@mui/material';
import { getTeamData, updateTeamMemberRole } from '../services/api';
import Toaster from '../common/Toaster';

interface TeamMember {
  id: string;
  nickname: string;
}

export default function ChangeTeamMemberRole() {
  const [teamId, setTeamId] = useState('');
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [selectedRole, setSelectedRole] = useState<'leader' | 'officer'>('leader');
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [toaster, setToaster] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

  const fetchTeamMembers = async () => {
    if (!teamId) return;
    setLoading(true);
    try {
      const response = await getTeamData(teamId);
      const members = response.data.members.map((member: any) => ({
        id: member.user_id,
        nickname: member.nickname
      }));
      setTeamMembers(members);
    } catch (error) {
      console.error('Failed to fetch team members:', error);
      setToaster({
        open: true,
        message: 'Failed to fetch team members. Please try again later.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!teamId || !selectedMemberId || !selectedRole) return;
    setUpdating(true);

    try {
      const response = await updateTeamMemberRole(teamId, selectedMemberId, selectedRole);
      setToaster({
        open: true,
        message: response.data.message,
        severity: 'success',
      });
      // Reset form
      setTeamId('');
      setSelectedMemberId('');
      setSelectedRole('leader');
      setTeamMembers([]);
    } catch (error) {
      console.error('Failed to update team member role:', error);
      setToaster({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to update team member role. Please try again.',
        severity: 'error',
      });
    } finally {
      setUpdating(false);
    }
  };

  const handleCloseToaster = () => {
    setToaster(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            Change Team Member Role
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3 }}>
              <Box sx={{ flex: 1 }}>
                <TextField
                  fullWidth
                  label="Team ID"
                  value={teamId}
                  onChange={(e) => setTeamId(e.target.value)}
                  required
                  sx={{
                    '& .MuiInputBase-input': { color: 'text.primary' },
                    '& .MuiInputLabel-root': { color: 'text.secondary' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'divider' },
                      '&:hover fieldset': { borderColor: 'primary.main' },
                      '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                    },
                  }}
                />
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    onClick={fetchTeamMembers}
                    disabled={!teamId || loading}
                    sx={{ 
                      backgroundColor: 'primary.main',
                      '&:hover': { backgroundColor: 'primary.dark' },
                    }}
                  >
                    {loading ? 'Fetching...' : 'Fetch Team Members'}
                  </Button>
                  {loading && <CircularProgress size={24} sx={{ color: 'primary.main' }} />}
                </Box>
              </Box>
              <Box sx={{ flex: 1 }}>
                {teamMembers.length > 0 && (
                  <>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="select-member-label" sx={{ color: 'text.secondary' }}>
                        Select Member
                      </InputLabel>
                      <Select
                        labelId="select-member-label"
                        value={selectedMemberId}
                        onChange={(e) => setSelectedMemberId(e.target.value as string)}
                        required
                        sx={{
                          color: 'text.primary',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'divider' },
                          '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.main' },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.main' },
                          '& .MuiSvgIcon-root': { color: 'text.primary' },
                        }}
                      >
                        {teamMembers.map((member) => (
                          <MenuItem key={member.id} value={member.id}>
                            {member.nickname}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="select-role-label" sx={{ color: 'text.secondary' }}>
                        Select Role
                      </InputLabel>
                      <Select
                        labelId="select-role-label"
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value as 'leader' | 'officer')}
                        required
                        sx={{
                          color: 'text.primary',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'divider' },
                          '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.main' },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.main' },
                          '& .MuiSvgIcon-root': { color: 'text.primary' },
                        }}
                      >
                        <MenuItem value="leader">Leader</MenuItem>
                        <MenuItem value="officer">Officer</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              </Box>
            </Box>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                disabled={updating || !teamId || !selectedMemberId || !selectedRole}
                sx={{ 
                  minWidth: '200px',
                  backgroundColor: 'primary.main',
                  '&:hover': { backgroundColor: 'primary.dark' },
                }}
              >
                {updating ? 'Updating...' : 'Update Member Role'}
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
}
import React from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

interface ToasterProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  onClose: () => void;
}

const Toaster: React.FC<ToasterProps> = ({ open, message, severity, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;
import React from 'react';
import { Button, Container, Typography, Box, ThemeProvider, CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import theme from '../Theme';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: theme.palette.background.default,
}));

const Home = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href = 'https://tops.services/api/auth/faceit';
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledContainer maxWidth="sm">
        <Typography variant="h3" component="h1" gutterBottom color="primary">
          TOPS Helper
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            size="large"
          >
            Login with FACEIT
          </Button>
        </Box>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default Home;
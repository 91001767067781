import React, { useState, useCallback } from 'react';
import { 
  Typography, 
  Box, 
  TextField, 
  Button, 
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material';
import { getTeamDataForEdit, updateTeam } from '../services/api';
import Toaster from '../common/Toaster';
import { AlertColor } from '@mui/material';

const TeamEdit: React.FC = () => {
  const [teamId, setTeamId] = useState('');
  const [teamData, setTeamData] = useState({ name: '', nickname: '' });
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [toaster, setToaster] = useState<{ open: boolean; message: string; severity: AlertColor }>({
    open: false,
    message: '',
    severity: 'success'
  });

  const fetchTeamData = useCallback(async () => {
    if (!teamId) return;
    setLoading(true);
    try {
      const response = await getTeamDataForEdit(teamId);
      setTeamData(response.data);
      setIsEditing(true);
    } catch (error) {
      console.error('Failed to fetch team data:', error);
      setToaster({
        open: true,
        message: 'Failed to fetch team data. Please try again later.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [teamId]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'teamId') {
      setTeamId(value);
    } else {
      setTeamData(prevData => ({ ...prevData, [name]: value }));
    }
  };

  const handleFetchTeam = (e: React.FormEvent) => {
    e.preventDefault();
    fetchTeamData();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!teamId) return;
    setUpdating(true);

    try {
      await updateTeam({
        teamId,
        newName: teamData.name,
        newTag: teamData.nickname,
      });

      setToaster({
        open: true,
        message: 'Team updated successfully!',
        severity: 'success',
      });

      setTimeout(() => {
        setIsEditing(false);
        setTeamId('');
        setTeamData({ name: '', nickname: '' });
      }, 2000);
    } catch (error) {
      console.error('Failed to update team:', error);
      setToaster({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to update team. Please try again.',
        severity: 'error',
      });
    } finally {
      setUpdating(false);
    }
  };

  const handleCloseToaster = () => {
    setToaster(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            Edit Team
          </Typography>
          {!isEditing ? (
            <form onSubmit={handleFetchTeam}>
              <TextField
                fullWidth
                label="Team ID"
                name="teamId"
                value={teamId}
                onChange={handleInputChange}
                margin="normal"
                required
                sx={{
                  mb: 2,
                  '& .MuiInputBase-input': { color: 'text.primary' },
                  '& .MuiInputLabel-root': { color: 'text.secondary' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'divider' },
                    '&:hover fieldset': { borderColor: 'primary.main' },
                    '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                  },
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!teamId || loading}
                  sx={{ 
                    minWidth: '200px',
                    backgroundColor: 'primary.main',
                    '&:hover': { backgroundColor: 'primary.dark' },
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Fetch Team Details'}
                </Button>
              </Box>
            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Team Name"
                name="name"
                value={teamData.name}
                onChange={handleInputChange}
                margin="normal"
                required
                inputProps={{ maxLength: 15 }}
                helperText="Max 15 characters"
                sx={{
                  mb: 2,
                  '& .MuiInputBase-input': { color: 'text.primary' },
                  '& .MuiInputLabel-root': { color: 'text.secondary' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'divider' },
                    '&:hover fieldset': { borderColor: 'primary.main' },
                    '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                  },
                }}
              />
              <TextField
                fullWidth
                label="Team Tag"
                name="nickname"
                value={teamData.nickname}
                onChange={handleInputChange}
                margin="normal"
                required
                inputProps={{ maxLength: 8 }}
                helperText="Max 8 characters"
                sx={{
                  mb: 2,
                  '& .MuiInputBase-input': { color: 'text.primary' },
                  '& .MuiInputLabel-root': { color: 'text.secondary' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'divider' },
                    '&:hover fieldset': { borderColor: 'primary.main' },
                    '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                  },
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={updating}
                  sx={{ 
                    minWidth: '150px',
                    backgroundColor: 'primary.main',
                    '&:hover': { backgroundColor: 'primary.dark' },
                  }}
                >
                  {updating ? <CircularProgress size={24} /> : 'Update Team'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsEditing(false);
                    setTeamId('');
                    setTeamData({ name: '', nickname: '' });
                  }}
                  sx={{ 
                    minWidth: '150px',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    '&:hover': { borderColor: 'primary.dark', color: 'primary.dark' },
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </CardContent>
      </Card>
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
};

export default TeamEdit;
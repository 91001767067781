import React, { useState } from 'react';
import { Typography, TextField, Button, Grid, Paper, MenuItem, Select, FormControl, InputLabel, Box } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { getUserIdFromNickname, createGameSpecificBan, getChampionships } from '../services/api';
import { SelectChangeEvent } from '@mui/material/Select';

interface GameBanData {
  userId: string;
  organizerId: string;
  expireDate: Dayjs | null;
  reason: string;
  game: string;
  useSmartBan: boolean;
}

interface Championship {
  game: string;
  // ... other fields if needed
}

const GameBans: React.FC = () => {
  const [nicknameOrUserId, setNicknameOrUserId] = useState('');
  const [banData, setBanData] = useState<GameBanData>({
    userId: '',
    organizerId: '',
    expireDate: null,
    reason: '',
    game: '',
    useSmartBan: false
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [games, setGames] = useState<string[]>([]);
  const [csvUsers, setCsvUsers] = useState<string[]>([]);
  const [processingCsv, setProcessingCsv] = useState(false);

  React.useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await getChampionships();
        // Extract unique game names from championships
        const uniqueGames = [...new Set(response.data.map((champ: Championship) => champ.game))];
        setGames(uniqueGames);
      } catch (error) {
        console.error('Error fetching games:', error);
        setError('Failed to load games');
      }
    };

    fetchGames();
  }, []);

  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setBanData({ ...banData, [name]: value });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setBanData({ ...banData, [name]: value });
  };

  const handleDateChange = (date: Dayjs | null) => {
    setBanData({ ...banData, expireDate: date });
  };

  const handleCsvUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        const users = text.split('\n').map(line => line.trim()).filter(line => line);
        setCsvUsers(users);
        setNicknameOrUserId(users.join(', '));
      };
      reader.readAsText(file);
    }
  };

  const handleSmartBanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBanData({ 
      ...banData, 
      useSmartBan: event.target.checked,
      expireDate: event.target.checked ? null : banData.expireDate 
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      if (csvUsers.length > 0) {
        setProcessingCsv(true);
        for (const user of csvUsers) {
          let userId = user;
          if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(user)) {
            const response = await getUserIdFromNickname(user);
            userId = response.data.userId;
          }
          await createGameSpecificBan({
            ...banData,
            userId,
            expireDate: banData.useSmartBan ? null : (banData.expireDate?.toDate() || null),
          });
        }
        setCsvUsers([]);
      } else {
        let userId = nicknameOrUserId;
        if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(nicknameOrUserId)) {
          const response = await getUserIdFromNickname(nicknameOrUserId);
          userId = response.data.userId;
        }
        await createGameSpecificBan({
          ...banData,
          userId,
          expireDate: banData.useSmartBan ? null : (banData.expireDate?.toDate() || null),
        });
      }

      // Reset form
      setNicknameOrUserId('');
      setBanData({
        userId: '',
        organizerId: '',
        expireDate: null,
        reason: '',
        game: '',
        useSmartBan: false
      });
      
      setSuccessMessage('Game-specific ban(s) created successfully');
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message === 'Player not found' 
          ? `Player not found: ${nicknameOrUserId}` 
          : 'An error occurred while creating the ban. Please try again.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      console.error('Error creating game-specific ban:', err);
    } finally {
      setLoading(false);
      setProcessingCsv(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Create Game-Specific Ban
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" gap={2}>
                <TextField
                  fullWidth
                  label="Nickname or User ID"
                  value={nicknameOrUserId}
                  onChange={(e) => setNicknameOrUserId(e.target.value)}
                  required={csvUsers.length === 0}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{ minWidth: '120px' }}
                >
                  Upload CSV
                  <input
                    type="file"
                    hidden
                    accept=".csv"
                    onChange={handleCsvUpload}
                  />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="organizerId"
                label="Organizer ID"
                value={banData.organizerId}
                onChange={handleTextInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="game-select-label">Game</InputLabel>
                <Select
                  labelId="game-select-label"
                  name="game"
                  value={banData.game}
                  label="Game"
                  onChange={handleSelectChange}
                >
                  {games.map((game) => (
                    <MenuItem key={game} value={game}>
                      {game.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <Box display="flex" alignItems="center" gap={1}>
                  <input
                    type="checkbox"
                    checked={banData.useSmartBan}
                    onChange={handleSmartBanChange}
                    id="smart-ban-checkbox"
                  />
                  <Typography component="label" htmlFor="smart-ban-checkbox">
                    Use Smart Ban System
                  </Typography>
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Expire Date"
                value={banData.expireDate}
                onChange={handleDateChange}
                slotProps={{ 
                  textField: { 
                    fullWidth: true,
                    helperText: banData.useSmartBan ? 
                      "Smart ban duration will be calculated automatically" : 
                      undefined
                  } 
                }}
                disabled={banData.useSmartBan}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="reason"
                label="Reason"
                value={banData.reason}
                onChange={handleTextInputChange}
                multiline
                rows={4}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                disabled={loading || processingCsv}
              >
                {processingCsv ? 'Processing CSV...' : loading ? 'Creating Ban...' : 'Create Ban'}
              </Button>
            </Grid>
          </Grid>
        </form>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        {successMessage && (
          <Typography color="success" sx={{ mt: 2 }}>
            {successMessage}
          </Typography>
        )}
      </Paper>
    </LocalizationProvider>
  );
};

export default GameBans;

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Layout from './components/Layout/Layout';
import Home from './components/Home';
import Dashboard from './components/Dashboard/Dashboard';
import Profile from './components/Profile';
import CreateTeam from './components/TeamManagement/CreateTeam';
import InviteTeamMembers from './components/TeamManagement/InviteTeamMembers';
import TeamEdit from './components/TeamManagement/TeamEdit';
import ChangeTeamMemberRole from './components/TeamManagement/ChangeTeamRole';
import KickTeamMember from './components/TeamManagement/KickTeamMember';
import DeleteTeam from './components/TeamManagement/DeleteTeam';
import BulkSignup from './components/Championships/BulkSignup';
import TeamSignup from './components/Championships/TeamSignup';
import ManageUsers from './components/Admin/ManageUsers';
import ProtectedRoute from './components/ProtectedRoute';
import ManageCronjobs from './components/Admin/ManageCronjobs';
import ManageOrganizers from './components/Admin/ManageOrganizers';
import ManageRegions from './components/Admin/ManageRegions';
import OrganizerBans from './components/LiveOPS/OrganizerBans';
import ViewBanList from './components/LiveOPS/ViewBanList';
import GameBans from './components/LiveOPS/Gamebans';
import CSVtoJSON from './components/LiveOPS/CSVtoJSON';
import Sections from './components/LiveOPS/Sections';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get('https://tops.services/api/user', { withCredentials: true });
        setIsAuthenticated(!!response.data);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or a more sophisticated loading component
  }

  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Home />} 
        />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Home />} />
        {isAuthenticated ? (
          <Route element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            
            {/* Team Management */}
            <Route path="/create-team" element={<CreateTeam />} />
            <Route path="/invite-team-members" element={<InviteTeamMembers />} />
            <Route path="/edit-team" element={<TeamEdit />} />
            <Route path="/change-team-member-role" element={<ChangeTeamMemberRole />} />
            <Route path="/kick-team-member" element={<KickTeamMember />} />
            <Route path="/delete-team" element={<DeleteTeam />} />
            
            {/* Championships signup */}
            <Route path="/bulk-player-signup" element={<BulkSignup />} />
            <Route path="/team-signup" element={<TeamSignup />} />

            {/* LiveOPS */}
                        <Route path="/organizer-bans" element={<OrganizerBans />} />
                        <Route path="/game-bans" element={<GameBans />} />
                        <Route path="/csv-to-json" element={<CSVtoJSON />} />
            <Route path="/view-banlist" element={<ViewBanList />} />
            <Route path="/sections" element={<Sections />} />
            {/* Owner Tools */}
            <Route 
              path="/manage-users" 
              element={
                <ProtectedRoute requiredRole="Owner">
                  <ManageUsers />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/manage-cronjobs" 
              element={
                <ProtectedRoute requiredRole="Owner">
                  <ManageCronjobs />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/manage-organizers" 
              element={
                <ProtectedRoute requiredRole="Owner">
                  <ManageOrganizers />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/manage-regions" 
              element={
                <ProtectedRoute requiredRole="Owner">
                  <ManageRegions />
                </ProtectedRoute>
              } 
            />
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
import React, { useState } from 'react';
import { Typography, TextField, Button, Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { getUserIdFromNickname, createOrganizerBan } from '../services/api';

interface BanData {
  userId: string;
  organizerId: string;
  expireDate: Dayjs | null;
  reason: string;
  isSmartBan: boolean;
}

const OrganizerBans: React.FC = () => {
  const [nicknameOrUserId, setNicknameOrUserId] = useState('');
  const [banData, setBanData] = useState<BanData>({
    userId: '',
    organizerId: '',
    expireDate: null,
    reason: '',
    isSmartBan: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setBanData({ ...banData, [name]: value });
  };

  const handleDateChange = (date: Dayjs | null) => {
    setBanData({ ...banData, expireDate: date });
  };

  const handleSmartBanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBanData({ ...banData, isSmartBan: event.target.checked });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      let userId = nicknameOrUserId;
      // Always call getUserIdFromNickname, the backend will handle if it's already a valid ID
      const response = await getUserIdFromNickname(nicknameOrUserId);
      userId = response.data.userId;

      await createOrganizerBan({
        ...banData,
        userId,
        expireDate: banData.expireDate ? banData.expireDate.toDate() : null,
      });

      // Reset form after successful submission
      setNicknameOrUserId('');
      setBanData({
        userId: '',
        organizerId: '',
        expireDate: null,
        reason: '',
        isSmartBan: false,
      });
      
      setSuccessMessage('Ban created successfully');
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message === 'Player not found' 
          ? `Player not found: ${nicknameOrUserId}` 
          : 'An error occurred while creating the ban. Please try again.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      console.error('Error creating ban:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Create Organizer Ban
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nickname or User ID"
                value={nicknameOrUserId}
                onChange={(e) => setNicknameOrUserId(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="organizerId"
                label="Organizer ID"
                value={banData.organizerId}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Expire Date"
                value={banData.expireDate}
                onChange={handleDateChange}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="reason"
                label="Reason"
                value={banData.reason}
                onChange={handleInputChange}
                multiline
                rows={4}
                required
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={banData.isSmartBan}
                    onChange={handleSmartBanChange}
                    name="isSmartBan"
                  />
                }
                label="Smart Ban"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                {loading ? 'Creating Ban...' : 'Create Ban'}
              </Button>
            </Grid>
          </Grid>
        </form>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        {successMessage && (
          <Typography color="success" sx={{ mt: 2 }}>
            {successMessage}
          </Typography>
        )}
      </Paper>
    </LocalizationProvider>
  );
};

export default OrganizerBans;
import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Drawer, List, ListItemIcon, ListItemText, ListItemButton, Collapse, Typography, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import TeamManagementIcon from '@mui/icons-material/Group';
import TournamentManagementIcon from '@mui/icons-material/EmojiEvents';
import AdminToolsIcon from '@mui/icons-material/Build';
import OwnerToolsIcon from '@mui/icons-material/Security';
import ProfileIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/ExitToApp';

interface User {
  roles: string[];
}

interface SubItem {
  name: string;
  path: string;
}

interface MenuItem {
  title: string;
  icon: React.ReactNode;
  subItems: SubItem[];
  visibleFor?: string[];
}

interface SidebarProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

export default function Sidebar({ mobileOpen, handleDrawerToggle }: SidebarProps) {
  const [user, setUser] = useState<User | null>(null);
  const [openMenus, setOpenMenus] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get<User>('https://tops.services/api/user', { withCredentials: true });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user:', error);
        navigate('/');
      }
    };

    fetchUser();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await axios.get('https://tops.services/api/auth/logout', { withCredentials: true });
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleMenuClick = (menu: string) => {
    setOpenMenus((prevState) => ({ ...prevState, [menu]: !prevState[menu] }));
  };

  const menuItems: MenuItem[] = [
    {
      title: 'Team Management',
      icon: <TeamManagementIcon />,
      subItems: [
        { name: 'Create Team', path: '/create-team' },
        { name: 'Invite Team Members', path: '/invite-team-members' },
        { name: 'Edit Team', path: '/edit-team' },
        { name: 'Change Team Members Role', path: '/change-team-member-role' },
        { name: 'Kick Members', path: '/kick-team-member' },
        { name: 'Delete Team', path: '/delete-team' },
      ],
    },
    {
      title: 'Tournament Management',
      icon: <TournamentManagementIcon />,
      subItems: [
        { name: 'Bulk Player Signup', path: '/bulk-player-signup' },
        { name: 'Team Signup', path: '/team-signup' },
      ],
    },
    {
      title: 'Admin Tools',
      icon: <AdminToolsIcon />,
      subItems: [
        { name: 'CSV to JSON', path: '/csv-to-json' },
        { name: 'Add Organizer Ban', path: '/organizer-bans'},
        { name: 'Add Game Ban', path: '/game-bans'},
        { name: 'View banlist', path: '/view-banlist' },
        { name: 'Countries Whitelist', path: '/countries-whitelist' },
        { name: 'Organizer Sections', path: '/sections' },
      ],
    },
    {
      title: 'Owner Tools',
      icon: <OwnerToolsIcon />,
      subItems: [
        { name: 'Manage Users', path: '/manage-users' },
        { name: 'Manage Cronjobs', path: '/manage-cronjobs' },
        { name: 'Manage Organizers', path: '/manage-organizers' },
        { name: 'Manage Regions', path: '/manage-regions' },
      ],
      visibleFor: ['Owner'],
    },
  ];

  const drawer = (
    <div>
      <Typography variant="h6" sx={{ p: 2, color: 'primary.main' }}>TOPS Helper</Typography>
      <List>
        {menuItems.map((item) => (
          (!item.visibleFor || (user?.roles && user.roles.some(role => item.visibleFor?.includes(role)))) && (
            <React.Fragment key={item.title}>
              <ListItemButton 
                onClick={() => handleMenuClick(item.title)}
                selected={item.subItems.some(subItem => location.pathname === subItem.path)}
              >
                <ListItemIcon sx={{ color: 'primary.main' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
                {openMenus[item.title] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openMenus[item.title]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem) => (
                    <ListItemButton
                      key={subItem.name}
                      component={Link}
                      to={subItem.path}
                      sx={{ pl: 4 }}
                      selected={location.pathname === subItem.path}
                    >
                      <ListItemText primary={subItem.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          )
        ))}
        <ListItemButton onClick={() => navigate('/profile')} selected={location.pathname === '/profile'}>
          <ListItemIcon sx={{ color: 'primary.main' }}><ProfileIcon /></ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon sx={{ color: 'primary.main' }}><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </div>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: theme.spacing(30) },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: theme.spacing(30) },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
}
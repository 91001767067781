import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const API_BASE_URL = 'https://tops.services/api';

const api: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('faceit_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      // Instead of redirecting, just remove the token
      localStorage.removeItem('faceit_token');
    }
    return Promise.reject(error);
  }
);

interface LoginCredentials {
  code: string;
}

interface LoginResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
}

interface TeamData {
  team_name: string;
  team_tag: string;
  game: string;
}

interface InviteTeamMembersData {
  teamId: string;
  members: string[];
}
interface TeamData {
  team_name: string;
  team_tag: string;
  game: string;
}


interface UpdateTeamData {
  teamId: string;
  newName: string;
  newTag: string;
}

interface KickTeamMemberData {
  teamId: string;
  userId: string;
}

export const login = async (credentials: LoginCredentials): Promise<AxiosResponse<LoginResponse>> => {
  const response = await api.post<LoginResponse>('/auth/faceit/callback', credentials);
  if (response.data.access_token) {
    localStorage.setItem('faceit_token', response.data.access_token);
  }
  return response;
};

export const logout = async (): Promise<AxiosResponse> => {
  const response = await api.get('/auth/logout');
  localStorage.removeItem('faceit_token');
  return response;
};

interface BulkSignupData {
  players: string[];
  champId: string;
}

interface TeamSignupData {
  teamId: string;
  champId: string;
  teamSize: number;
  roster: string[];
  substitutes: string[];
  coach: string[];
}

interface UpdateCronjobData {
  name?: string;
  description?: string;
  schedule?: string;
  isActive?: boolean;
}

export interface Cronjob {
  _id: string;
  name: string;
  description: string;
  jobType: string;
  schedule: string;
  isActive: boolean;
  lastRun?: Date;
  nextRun?: Date;
}
// Update getCurrentUser function
export const getCurrentUser = async (): Promise<AxiosResponse | null> => {
  try {
    return await api.get('/user');
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      return null;  // Return null for unauthorized instead of throwing an error
    }
    throw error;
  }
};

export const createTeam = async (teamData: TeamData): Promise<AxiosResponse> => {
  return api.post('/teams/create_team', teamData);
};

export const getGames = async (): Promise<AxiosResponse> => {
  return api.get('/teams/create_team');
};

export const getTeamData = async (teamId: string): Promise<AxiosResponse> => {
  return api.get(`/teams/${teamId}`);
};

export const inviteTeamMembers = async (inviteData: InviteTeamMembersData): Promise<AxiosResponse> => {
  return api.post('/teams/invite_team_members', inviteData);
};
export const update = async (inviteData: InviteTeamMembersData): Promise<AxiosResponse> => {
  return api.post('/teams/invite_team_members', inviteData);
};
export const getTeamDataForEdit = async (teamId: string): Promise<AxiosResponse> => {
  return api.get(`/teams/${teamId}`);
};

export const updateTeam = async (updateData: UpdateTeamData): Promise<AxiosResponse> => {
  return api.put(`/teams/${updateData.teamId}`, { newName: updateData.newName, newTag: updateData.newTag });
};

export const getTeamMembers = async (teamId: string): Promise<AxiosResponse> => {
  return api.get(`/teams/${teamId}/members`);
};

export const updateTeamLeader = async (teamId: string, newLeaderId: string): Promise<AxiosResponse> => {
  return api.put(`/teams/${teamId}/leader`, { newLeaderId });
};

export const updateTeamMemberRole = async (teamId: string, memberId: string, role: 'leader' | 'officer'): Promise<AxiosResponse> => {
  return api.put(`/teams/${teamId}/members/${memberId}/role`, { role });
};

export const kickTeamMember = async (kickData: KickTeamMemberData): Promise<AxiosResponse> => {
  return api.delete(`/teams/${kickData.teamId}/kick/${kickData.userId}`);
};

export const deleteTeam = async (teamId: string): Promise<AxiosResponse> => {
  return api.delete(`/teams/${teamId}`);
};

export const bulkSignup = async (data: BulkSignupData): Promise<AxiosResponse> => {
  return api.post('/bulk_signup', data);
};

export const performTeamSignup = async (data: TeamSignupData): Promise<AxiosResponse> => {
  return api.post('/perform_team_signup', data);
};

export const getAllUsers = async (): Promise<AxiosResponse> => {
  return api.get('/admin/users');
};
export const updateUserRoles = async (userId: string, roles: string[]): Promise<AxiosResponse> => {
  return api.put(`/admin/users/${userId}/roles`, { roles });
};

export const updateCronjob = async (id: string, updates: UpdateCronjobData): Promise<AxiosResponse<Cronjob>> => {
  return api.put(`/cronjobs/${id}`, updates);
};

export const deleteCronjob = async (id: string): Promise<AxiosResponse> => {
  return api.delete(`/cronjobs/${id}`);
};
export const getAllCronjobs = (): Promise<AxiosResponse<Cronjob[]>> => {
  return api.get('/cronjobs');
};

export const createCronjob = (cronjobData: Omit<Cronjob, '_id' | 'isActive'>): Promise<AxiosResponse<Cronjob>> => {
  return api.post('/cronjobs', cronjobData);
};
export const runCronjob = (id: string): Promise<AxiosResponse> => {
  return api.post(`/cronjobs/${id}/run`);
};
export const getUserIdFromNickname = (nickname: string): Promise<AxiosResponse<{ userId: string }>> => {
  return api.get(`/users/nickname-to-id/${nickname}`);
};

export const createOrganizerBan = (banData: {
  userId: string;
  organizerId: string;
  expireDate: Date | null;
  reason: string;
  isSmartBan: boolean;
}): Promise<AxiosResponse> => {
  return api.post('/organizer-bans', banData);
};
export const createGameSpecificBan = async (banData: {
  userId: string;
  organizerId: string;
  expireDate: Date | null;
  reason: string;
  game: string;
  useSmartBan: boolean;
}) => {
  const response = await axios.post('/api/game-specific-bans', banData);
  return response;
};

export const getOrganizerBans = (status?: 'active' | 'expired'): Promise<AxiosResponse> => {
  const params = status ? { status } : {};
  return api.get('/organizer-bans', { params });
};
interface Championship {
  _id: string;
  id: string;
  game: string;
  name: string;
  organizerId: string;
  status: string;
  region?: string;
  createdAt: Date;
  updatedAt: Date;
}

// Add this function to get championships
export const getChampionships = async (): Promise<AxiosResponse<Championship[]>> => {
  return api.get('/championships');
};



interface BanListRecord {
  userId: string;
  game: string;
  organizerId: string;
  reason: string;
  status: string;
  createdAt: string;
  expireDate: Date | null;
  tournamentCount: number;
  lastProcessed: Date | null;
}

// Add this new function
export const getBanList = (
  page: number = 1,
  limit: number = 50,
  search: string = ''
): Promise<AxiosResponse<{ data: BanListRecord[]; total: number; page: number; limit: number }>> => {
  return api.get('/ban-list', { params: { page, limit, search } });
};

interface Section {
  name: string;
  tournamentType: string;
  competitions: string[];
}

interface SectionsData {
  sections: Section[];
}

export const createSections = async (organizerId: string, sectionsData: SectionsData): Promise<AxiosResponse> => {
  return api.post(`/${organizerId}/sections`, sectionsData);
};

export const updateSections = async (organizerId: string, sectionsData: SectionsData): Promise<AxiosResponse> => {
  return api.put(`/${organizerId}/sections`, sectionsData);
};

export const getSections = async (organizerId: string): Promise<AxiosResponse> => {
  return api.get(`/${organizerId}/sections`);
};

export default api;
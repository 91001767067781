import React, { useState } from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  TextField,
  Link,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Toaster from '../common/Toaster';
import api from '../services/api';

const Input = styled('input')({
  display: 'none',
});

const CSVtoJSON: React.FC = () => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [featureNumber, setFeatureNumber] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string>('');
  const [toaster, setToaster] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setCsvFile(event.target.files[0]);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!csvFile || !featureNumber) {
      setToaster({
        open: true,
        message: 'Please upload a file and enter a feature number.',
        severity: 'error'
      });
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('csv_file', csvFile);
    formData.append('feature_number', featureNumber);

    try {
      const response = await api.post('/csv-to-json', formData, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadLink(url);
      setToaster({
        open: true,
        message: 'File converted successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error converting file:', error);
      setToaster({
        open: true,
        message: 'Failed to convert file. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseToaster = () => {
    setToaster(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '600px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            CSV to JSON Converter
          </Typography>

          <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <label htmlFor="csv-upload">
                <Input
                  id="csv-upload"
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                />
                <Button variant="contained" component="span" fullWidth>
                  Upload CSV File
                </Button>
              </label>
              {csvFile && (
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  Selected: {csvFile.name}
                </Typography>
              )}
            </Box>

            <TextField
              label="Feature Number"
              type="number"
              value={featureNumber}
              onChange={(e) => setFeatureNumber(e.target.value)}
              fullWidth
              required
            />

            <Button 
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Convert & Download'}
            </Button>

            {downloadLink && (
              <Link 
                href={downloadLink} 
                download="formatted_data.json"
                sx={{ textAlign: 'center', mt: 2 }}
              >
                Download JSON File
              </Link>
            )}
          </Box>
        </CardContent>
      </Card>

      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
};

export default CSVtoJSON;

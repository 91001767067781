import React, { useState } from 'react';
import { 
  Typography, 
  Box, 
  TextField, 
  Button, 
  Card, 
  CardContent, 
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { bulkSignup } from '../services/api';
import Toaster from '../common/Toaster';

interface SignupResult {
  player: string;
  success: boolean;
  message: string;
}

const BulkSignup: React.FC = () => {
  const [players, setPlayers] = useState('');
  const [champId, setChampId] = useState('');
  const [results, setResults] = useState<SignupResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [toaster, setToaster] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setResults([]);

    const playerList = players.split('\n').map(p => p.trim()).filter(p => p);

    if (playerList.length > 50) {
      setToaster({
        open: true,
        message: 'Maximum 50 players allowed',
        severity: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      const response = await bulkSignup({ players: playerList, champId });
      setResults(response.data);
      const successCount = response.data.filter((result: SignupResult) => result.success).length;
      setToaster({
        open: true,
        message: `Successfully signed up ${successCount} out of ${response.data.length} players.`,
        severity: 'success',
      });
    } catch (error) {
      console.error('Error during bulk signup:', error);
      setToaster({
        open: true,
        message: 'An error occurred during bulk signup. Please try again.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseToaster = () => {
    setToaster(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            Bulk Signup
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Players (one per line, max 50)"
              multiline
              rows={10}
              value={players}
              onChange={(e) => setPlayers(e.target.value)}
              margin="normal"
              required
              helperText="Enter player IDs or nicknames, one per line. Maximum 50 players."
              sx={{
                mb: 2,
                '& .MuiInputBase-input': { color: 'text.primary' },
                '& .MuiInputLabel-root': { color: 'text.secondary' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'divider' },
                  '&:hover fieldset': { borderColor: 'primary.main' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                },
              }}
            />
            <TextField
              fullWidth
              label="Championship ID"
              value={champId}
              onChange={(e) => setChampId(e.target.value)}
              margin="normal"
              required
              sx={{
                mb: 2,
                '& .MuiInputBase-input': { color: 'text.primary' },
                '& .MuiInputLabel-root': { color: 'text.secondary' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'divider' },
                  '&:hover fieldset': { borderColor: 'primary.main' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                },
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{ 
                  minWidth: '200px',
                  backgroundColor: 'primary.main',
                  '&:hover': { backgroundColor: 'primary.dark' },
                }}
              >
                {loading ? 'Signing Up...' : 'Sign Up'}
              </Button>
            </Box>
          </form>
          {loading && <LinearProgress sx={{ mt: 2 }} />}
          {results.length > 0 && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" gutterBottom sx={{ color: 'text.primary' }}>
                Results:
              </Typography>
              <List>
                {results.map((result, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`${result.player}: ${result.message}`}
                      sx={{
                        '& .MuiListItemText-primary': {
                          color: result.success ? 'success.main' : 'error.main',
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </CardContent>
      </Card>
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
};

export default BulkSignup;
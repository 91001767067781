import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Pagination,
  TextField,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getBanList } from '../services/api';
import Toaster from '../common/Toaster';

interface BanRecord {
  userId: string;
  game: string;
  organizerId: string;
  reason: string;
  status: string;
  createdAt: string;
  expireDate: Date | null;
  tournamentCount: number;
  lastProcessed: Date | null;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ViewBanList: React.FC = () => {
  const [bans, setBans] = useState<BanRecord[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState('');
  const [toaster, setToaster] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  useEffect(() => {
    fetchBans();
  }, [page]);

  const fetchBans = async () => {
    setLoading(true);
    try {
      const response = await getBanList(page, 50, search);
      setBans(response.data.data);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error('Error fetching bans:', error);
      setToaster({
        open: true,
        message: 'Failed to fetch ban list. Please try again.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleSearch = () => {
    setPage(1); // Reset to first page when searching
    fetchBans();
  };

  const handleCloseToaster = () => {
    setToaster((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            User Ban List
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <TextField
              label="Search by User or Organizer"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="outlined"
              size="small"
            />
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
          </Box>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper} elevation={3}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>User ID</StyledTableCell>
                    <StyledTableCell>Game</StyledTableCell>
                    <StyledTableCell>Organizer ID</StyledTableCell>
                    <StyledTableCell>Reason</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Ban Date</StyledTableCell>
                    <StyledTableCell>Expire Date</StyledTableCell>
                    <StyledTableCell>Tournament Count</StyledTableCell>
                    <StyledTableCell>Last Processed</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bans.map((ban) => (
                    <TableRow key={`${ban.userId}-${ban.game}`} hover>
                      <TableCell>{ban.userId}</TableCell>
                      <TableCell>
                        <StyledChip label={ban.game} color="primary" variant="outlined" />
                      </TableCell>
                      <TableCell>{ban.organizerId}</TableCell>
                      <TableCell>{ban.reason}</TableCell>
                      <TableCell>
                        <StyledChip
                          label={ban.status}
                          color={ban.status === 'active' ? 'error' : 'default'}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>{new Date(ban.createdAt).toLocaleDateString()}</TableCell>
                      <TableCell>
                        {ban.expireDate ? new Date(ban.expireDate).toLocaleDateString() : 'Permanent'}
                      </TableCell>
                      <TableCell>{ban.tournamentCount}</TableCell>
                      <TableCell>
                        {ban.lastProcessed ? new Date(ban.lastProcessed).toLocaleDateString() : 'Never'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Pagination
            count={Math.ceil(totalRecords / 50)}
            page={page}
            onChange={handlePageChange}
            sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
          />
        </CardContent>
      </Card>
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
};

export default ViewBanList;

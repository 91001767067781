import React, { useState } from 'react';
import {
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getTeamData, kickTeamMember } from '../services/api';
import Toaster from '../common/Toaster';

interface TeamMember {
  id: string;
  nickname: string;
}

export default function KickTeamMember() {
  const [teamId, setTeamId] = useState('');
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [loading, setLoading] = useState(false);
  const [kicking, setKicking] = useState(false);
  const [toaster, setToaster] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });
  const [confirmDialog, setConfirmDialog] = useState({ open: false, memberId: '', nickname: '' });

  const fetchTeamMembers = async () => {
    if (!teamId) return;
    setLoading(true);
    try {
      const response = await getTeamData(teamId);
      const members = response.data.members.map((member: any) => ({
        id: member.user_id,
        nickname: member.nickname
      }));
      setTeamMembers(members);
    } catch (error) {
      console.error('Failed to fetch team members:', error);
      setToaster({
        open: true,
        message: 'Failed to fetch team members. Please try again later.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleKickMember = async () => {
    if (!teamId || !confirmDialog.memberId) return;
    setKicking(true);
    try {
      await kickTeamMember({ teamId, userId: confirmDialog.memberId });
      setToaster({
        open: true,
        message: `Successfully kicked ${confirmDialog.nickname} from the team.`,
        severity: 'success',
      });
      setTeamMembers(teamMembers.filter(member => member.id !== confirmDialog.memberId));
    } catch (error) {
      console.error('Failed to kick team member:', error);
      setToaster({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to kick team member. Please try again.',
        severity: 'error',
      });
    } finally {
      setKicking(false);
      setConfirmDialog({ open: false, memberId: '', nickname: '' });
    }
  };

  const handleCloseToaster = () => {
    setToaster(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            Kick Team Member
          </Typography>
          <TextField
            fullWidth
            label="Team ID"
            value={teamId}
            onChange={(e) => setTeamId(e.target.value)}
            margin="normal"
            required
            sx={{
              mb: 2,
              '& .MuiInputBase-input': { color: 'text.primary' },
              '& .MuiInputLabel-root': { color: 'text.secondary' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'divider' },
                '&:hover fieldset': { borderColor: 'primary.main' },
                '&.Mui-focused fieldset': { borderColor: 'primary.main' },
              },
            }}
          />
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={3}>
            <Button
              variant="contained"
              onClick={fetchTeamMembers}
              disabled={!teamId || loading}
              sx={{ 
                backgroundColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.dark' },
              }}
            >
              {loading ? 'Fetching...' : 'Fetch Team Members'}
            </Button>
            {loading && <CircularProgress size={24} sx={{ color: 'primary.main' }} />}
          </Box>
          {teamMembers.length > 0 && (
            <List sx={{ bgcolor: 'background.paper', borderRadius: 1 }}>
              {teamMembers.map((member) => (
                <ListItem key={member.id} sx={{ color: 'text.primary' }}>
                  <ListItemText primary={member.nickname} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="kick"
                      onClick={() => setConfirmDialog({ open: true, memberId: member.id, nickname: member.nickname })}
                    >
                      <DeleteIcon sx={{ color: 'error.main' }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, memberId: '', nickname: '' })}
      >
        <DialogTitle>Confirm Kick</DialogTitle>
        <DialogContent>
          Are you sure you want to kick {confirmDialog.nickname} from the team?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog({ open: false, memberId: '', nickname: '' })} color="primary">
            Cancel
          </Button>
          <Button onClick={handleKickMember} color="error" autoFocus>
            {kicking ? 'Kicking...' : 'Kick'}
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
}
import React, { useState } from 'react';
import {
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
} from '@mui/material';
import { getTeamData, deleteTeam } from '../services/api';
import Toaster from '../common/Toaster';

interface TeamMember {
  id: string;
  nickname: string;
}

export default function DeleteTeam() {
  const [teamId, setTeamId] = useState('');
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [teamName, setTeamName] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [toaster, setToaster] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });
  const [confirmDialog, setConfirmDialog] = useState({ open: false });

  const fetchTeamData = async () => {
    if (!teamId) return;
    setLoading(true);
    try {
      const response = await getTeamData(teamId);
      const members = response.data.members.map((member: any) => ({
        id: member.user_id,
        nickname: member.nickname
      }));
      setTeamMembers(members);
      setTeamName(response.data.name);
    } catch (error) {
      console.error('Failed to fetch team data:', error);
      setToaster({
        open: true,
        message: 'Failed to fetch team data. Please try again later.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTeam = async () => {
    if (!teamId) return;
    setDeleting(true);
    try {
      await deleteTeam(teamId);
      setToaster({
        open: true,
        message: `Successfully deleted team ${teamName}.`,
        severity: 'success',
      });
      setTeamId('');
      setTeamMembers([]);
      setTeamName('');
    } catch (error) {
      console.error('Failed to delete team:', error);
      setToaster({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to delete team. Please try again.',
        severity: 'error',
      });
    } finally {
      setDeleting(false);
      setConfirmDialog({ open: false });
    }
  };

  const handleCloseToaster = () => {
    setToaster(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', pt: 3 }}>
      <Card sx={{ width: '100%', maxWidth: '1000px', backgroundColor: 'background.paper' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}>
            Delete Team
          </Typography>
          <TextField
            fullWidth
            label="Team ID"
            value={teamId}
            onChange={(e) => setTeamId(e.target.value)}
            margin="normal"
            required
            sx={{
              mb: 2,
              '& .MuiInputBase-input': { color: 'text.primary' },
              '& .MuiInputLabel-root': { color: 'text.secondary' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'divider' },
                '&:hover fieldset': { borderColor: 'primary.main' },
                '&.Mui-focused fieldset': { borderColor: 'primary.main' },
              },
            }}
          />
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={3}>
            <Button
              variant="contained"
              onClick={fetchTeamData}
              disabled={!teamId || loading}
              sx={{ 
                backgroundColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.dark' },
              }}
            >
              {loading ? 'Fetching...' : 'Fetch Team Data'}
            </Button>
            {loading && <CircularProgress size={24} sx={{ color: 'primary.main' }} />}
          </Box>
          {teamMembers.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom sx={{ color: 'text.primary', mt: 2 }}>
                Team Name: {teamName}
              </Typography>
              <Typography variant="h6" gutterBottom sx={{ color: 'text.primary', mt: 2 }}>
                Team Members:
              </Typography>
              <List sx={{ bgcolor: 'background.paper', borderRadius: 1, mb: 3 }}>
                {teamMembers.map((member) => (
                  <ListItem key={member.id} sx={{ color: 'text.primary' }}>
                    <ListItemText primary={member.nickname} />
                  </ListItem>
                ))}
              </List>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => setConfirmDialog({ open: true })}
                sx={{ mt: 2 }}
              >
                Delete Team
              </Button>
            </>
          )}
        </CardContent>
      </Card>
      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false })}
      >
        <DialogTitle>Confirm Team Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the team "{teamName}"? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog({ open: false })} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteTeam} color="error" autoFocus>
            {deleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onClose={handleCloseToaster}
      />
    </Box>
  );
}
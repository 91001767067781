import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, TextField, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getAllCronjobs, createCronjob, updateCronjob, deleteCronjob, runCronjob } from '../services/api';

interface Cronjob {
  _id: string;
  name: string;
  description: string;
  jobType: string;
  schedule: string;
  isActive: boolean;
  lastRun?: Date;
  nextRun?: Date;
}

const jobTypes = ['fetchTournaments', 'updateOrganizerBans', 'fetchFaceitTournaments', 'updateGameBans'];

const scheduleOptions = [
  { value: 'hourly', label: 'Every hour', cronExpression: '0 * * * *' },
  { value: 'daily', label: 'Every day at midnight', cronExpression: '0 0 * * *' },
  { value: 'weekly', label: 'Every week on Sunday at midnight', cronExpression: '0 0 * * 0' },
  { value: 'monthly', label: 'Every month on the 1st at midnight', cronExpression: '0 0 1 * *' },
  { value: 'custom', label: 'Custom schedule', cronExpression: '' },
];

const ManageCronjobs: React.FC = () => {
  const [cronjobs, setCronjobs] = useState<Cronjob[]>([]);
  const [newCronjob, setNewCronjob] = useState<Omit<Cronjob, '_id' | 'isActive'>>({
    name: '',
    description: '',
    jobType: '',
    schedule: '',
  });
  const [scheduleType, setScheduleType] = useState('hourly');
  const [customSchedule, setCustomSchedule] = useState('');

  useEffect(() => {
    fetchCronjobs();
  }, []);

  const fetchCronjobs = async () => {
    try {
      const response = await getAllCronjobs();
      setCronjobs(response.data);
    } catch (error) {
      console.error('Error fetching cronjobs:', error);
    }
  };

  const handleCreateCronjob = async () => {
    const schedule = scheduleType === 'custom' ? customSchedule : scheduleOptions.find(option => option.value === scheduleType)?.cronExpression || '';
    try {
      await createCronjob({ ...newCronjob, schedule });
      setNewCronjob({ name: '', description: '', jobType: '', schedule: '' });
      setScheduleType('hourly');
      setCustomSchedule('');
      fetchCronjobs();
    } catch (error) {
      console.error('Error creating cronjob:', error);
    }
  };

  const handleUpdateCronjob = async (id: string, updates: Partial<Cronjob>) => {
    try {
      await updateCronjob(id, updates);
      fetchCronjobs();
    } catch (error) {
      console.error('Error updating cronjob:', error);
    }
  };

  const handleDeleteCronjob = async (id: string) => {
    try {
      await deleteCronjob(id);
      fetchCronjobs();
    } catch (error) {
      console.error('Error deleting cronjob:', error);
    }
  };
  const handleRunJob = async (id: string) => {
    try {
      await runCronjob(id);
      await fetchCronjobs(); // Refresh the list to update lastRun and nextRun
    } catch (error) {
      console.error('Error running job:', error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Manage Cronjobs</Typography>
      
      <Box mb={4}>
        <TextField
          label="Name"
          value={newCronjob.name}
          onChange={(e) => setNewCronjob({ ...newCronjob, name: e.target.value })}
        />
        <TextField
          label="Description"
          value={newCronjob.description}
          onChange={(e) => setNewCronjob({ ...newCronjob, description: e.target.value })}
        />
        <FormControl fullWidth>
          <InputLabel>Job Type</InputLabel>
          <Select
            value={newCronjob.jobType}
            onChange={(e) => setNewCronjob({ ...newCronjob, jobType: e.target.value as string })}
          >
            {jobTypes.map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Schedule Type</InputLabel>
          <Select
            value={scheduleType}
            onChange={(e) => setScheduleType(e.target.value)}
          >
            {scheduleOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {scheduleType === 'custom' && (
          <TextField
            label="Custom Schedule"
            value={customSchedule}
            onChange={(e) => setCustomSchedule(e.target.value)}
            helperText="Enter a valid cron expression (e.g., '*/5 * * * *' for every 5 minutes)"
          />
        )}
        <Button onClick={handleCreateCronjob}>Create Cronjob</Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Job Type</TableCell>
              <TableCell>Schedule</TableCell>
              <TableCell>Last Run</TableCell>
              <TableCell>Next Run</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cronjobs.map((cronjob) => (
              <TableRow key={cronjob._id}>
                <TableCell>{cronjob.name}</TableCell>
                <TableCell>{cronjob.description}</TableCell>
                <TableCell>{cronjob.jobType}</TableCell>
                <TableCell>{cronjob.schedule}</TableCell>
                <TableCell>{cronjob.lastRun ? new Date(cronjob.lastRun).toLocaleString() : 'Never'}</TableCell>
                <TableCell>{cronjob.nextRun ? new Date(cronjob.nextRun).toLocaleString() : 'Not scheduled'}</TableCell>
                <TableCell>
                  <Switch
                    checked={cronjob.isActive}
                    onChange={(e) => handleUpdateCronjob(cronjob._id, { isActive: e.target.checked })}
                  />
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleRunJob(cronjob._id)}>Run Now</Button>
                  <Button onClick={() => handleDeleteCronjob(cronjob._id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ManageCronjobs;